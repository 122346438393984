<template>
  <b-sidebar
    #default="{ hide }"
    :visible="visible"
    bg-variant="white"
    right
    backdrop
    shadow
    no-header
    class="chat-sidebar b-sidebar-outer"
    no-close-on-route-change
    @change="handleSidebarChange"
    @shown="$emit('shown', $event)"
    @hidden="$emit('hidden', $event)"
  >
    <!-- Caht header -->
    <div class="mt-1 mx-2 chat-header">
      <b-row>
        <b-img :src="backgroundPlaceholder" class="chat-header-img" />
      </b-row>
      <b-row class="chat-header-bottom">
        <!-- Chat with user -->
        <b-col cols="10" class="p-0">
          <!--pre>{{ 'Chat:' + user.name + ' ' + user.surname  }}</!pre-->&nbsp;
          <b-link 
            v-if="user" 
            class="d-flex align-items-center w-100" 
            :to="getMemberProfileLink(user)"
          >
            <user-avatar
              :user="user"
              size="32"
              variant="transparent"
              class="avatar-border-2 box-shadow-1 mr-75"
            />
            <p class="title text-primary m-0 html-text-ellipsis-1 chat-name">
              {{ user.name + ' ' + user.surname }}
            </p>
          </b-link>
        </b-col>
        <!-- Close chat -->
        <b-col cols="2" class="p-0 d-flex justify-content-end close">
          <b-button
            variant="link"
            class="close-button text-secondary"
            block
            @click="hide"
          >
            <strong>X</strong>
          </b-button>
        </b-col>
      </b-row>
    </div>
    <!-- End header -->

    <slot 
      v-if="visible" 
      v-bind="{ hide }" 
      class="mb-0 rounded-0" 
    />
  </b-sidebar>
</template>

<script>
import UserAvatar from '@core/components/user/UserAvatar.vue';
import { CHAT_GETTERS } from '@/views/apps/chat/constants/chat-store-constants';
import HeaderPlaceholder from '@/assets/images/placeholders/light/header.jpeg';

export default {
  name: 'ChatSidebar',
  components: { UserAvatar },
  inject: ['$chatBot'],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    user: {
      type: Object,
      default: null,
    },
  },
  async created() {
    this.fetchMemberData();
  },

  computed: {
    username() {
      return this.$store.getters[CHAT_GETTERS.individualChatUsername];
    },
    backgroundPlaceholder() {
      return HeaderPlaceholder;
    },
    /*user() {
      if(this.user){
        return this.user;
      }
      if(this.$store.getters[CHAT_GETTERS.individualChatUser]?.chat11?.unpaginated){
        //console.log('found1');
        return this.$store.getters[CHAT_GETTERS.individualChatUser]?.chat11?.unpaginated[0];
      }
      if(this.$store.getters.communityMembers?.chat11?.unpaginated){
        //console.log('found!', this.$store.getters.communityMembers?.chat11?.unpaginated[0])
        return this.$store.getters.communityMembers?.chat11?.unpaginated[0];
      }
      // const member = this.fetchMemberData();
      // return member;
      // console.log('this.$store.getters[CHAT_GETTERS.individualChatUsername]', this.$store.getters[CHAT_GETTERS.individualChatUsername]);
      // return this.$store.getters[CHAT_GETTERS.individualChatUsername];
    },*/
  },

  watch: {
    // async user() {
    //   this.user = this.$store.getters.communityMembers?.chat11?.unpaginated[0];
    // },
  },

  methods: {
    async fetchMemberData() {
      //console.log('ChatSidebar fetchMemberData to get chat11 in communityMembers');
      let response = await this.$store.dispatch('getItems', {
        itemType: "communityMembers",
        storedKey: "chat11",
        params: {
          communitySlug: this.$route.params.communityId,
          username: this.username,
          isForProfile: false,
          getMinimalData: 1,
        },
        requestConfig: {
          communitySlug: this.$route.params.communityId,
          username: this.username,
          isForProfile: false,
          getMinimalData: 1,
        },
      });
      //console.log('response', response);
      if(!response){
        return response;
      }
      if(response.data){
        response = response.data;
      }
      if(response.data){
        response = response.data;
      }
      if(response[0]){
        response = response[0];
      }
      return response;
      //return response?.data?.data[0];
    },
    handleSidebarChange(isVisible) {
      this.$emit('change', isVisible);
      if (isVisible) {
        this.$chatBot.hide();
      } else {
        this.$chatBot.show();
      }
    },
    getMemberProfileLink(user) {
      //console.log('getMemberProfileLink of user:', user);
      if (!user.username) {
        return null;
      }
      return {
        name: 'member-details',
        params: { 
          username: user.username, 
          communityId: this.$route.params.communityId 
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-sidebar::v-deep .b-sidebar {
  width: 100%;
  z-index: 1;
}
@media (min-width: 600px) {
  .chat-sidebar::v-deep .b-sidebar {
    width: 50%;
    // padding-top: 55px;
  }
}
// Medium devices (tablets, 768px and up)
@media (min-width: 992px) {
  .chat-sidebar::v-deep .b-sidebar {
    width: 40%;
  }
}
.close {
  width: 100%;
}
.close-button {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.chat-name {
  color: #8ea5bc !important;
  font-weight: 700;
}
.chat-header {
  background-color: #ecf7f7;
  // background-color: #f6f6f6;
  margin: 0 !important;
  // height: 100px;
}

.chat-header-img {
  height: 56px;
  width: 100%;
  opacity: 0.3;
  border-bottom: 1px solid #cecece;
  // background-size: cover;
}
.chat-header-bottom {
  padding: 10px 2rem;
}
</style>
